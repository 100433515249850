import { NgModule } from '@angular/core';
import { DocumentationIntroComponent } from '@modules/documentation/pages/documentation-intro/documentation-intro.component';
import { DocumentationComponent } from '@modules/documentation/pages/documentation/documentation.component';
import { SsoDocumentationComponent } from '@modules/documentation/pages/sso-documentation/sso-documentation.component';
import { SharedModule } from '@shared/shared.module';
import { DocumentationPageNavigationComponent } from './components/documentation-page-navigation/documentation-page-navigation.component';
import { ResponseTypeTokenTableComponent } from './components/response-type-token-table/response-type-token-table.component';
import { DocumentationRoutingModule } from './documentation-routing.module';
import { OpenidConnectFlowsDocumentationComponent } from './pages/openid-connect-flows-documentation/openid-connect-flows-documentation.component';
import { OpenidConnectGrantTypesDocumentationComponent } from './pages/openid-connect-grant-types-documentation/openid-connect-grant-types-documentation.component';
import { OpenidConnectTokensDocumentationComponent } from './pages/openid-connect-tokens-documentation/openid-connect-tokens-documentation.component';
import { OpenidConnectComponent } from './pages/openid-connect/openid-connect.component';

@NgModule({
  declarations: [
    DocumentationIntroComponent,
    DocumentationComponent,
    DocumentationPageNavigationComponent,
    OpenidConnectFlowsDocumentationComponent,
    OpenidConnectGrantTypesDocumentationComponent,
    ResponseTypeTokenTableComponent,
    SsoDocumentationComponent,
    OpenidConnectTokensDocumentationComponent,
    OpenidConnectComponent,
  ],
  imports: [DocumentationRoutingModule, SharedModule],
})
export class DocumentationModule {}
