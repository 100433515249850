<h1>Supported flows</h1>
<p>The main goal of an authorization is to obtain an access token and an id_token.</p>
<p>
  The flow used is determined by the “response_type” parameter defined in the Oauth 2.0 standard. “response_type” can be
  any combination of “code”, “token” and “id_token”.
</p>

<app-documentation-page-navigation [menuItems]="navigationElements"></app-documentation-page-navigation>

<h2 id="auth-code-flow">Auth Code Flow</h2>
<p>In this flow, the “response_type” must be “code”. This is the most common flow.</p>

<img src="assets/documentation/openid-connect/flows/id-auth_code_flow.png" alt="Auth Code Flow, Flow Chart" />

<p>
  In this flow the client directs the user to ID’s authorization endpoint with “response_type=code”, if the user
  authenticates and authorizes, ID redirects the user to the client with a code. This code is exchanged with an
  id_token+access_token by sending a POST request to the token endpoint using “authorization_code” as “grant_type”.
</p>

<app-response-type-token-table [responseTypes]="authCodeFlowResponseTypes"></app-response-type-token-table>

<p>
  If the scope “offline_access” is added to the authorization request and the user authorizes the use of this scope an
  “refresh_token” is also returned on the token endpoint.
</p>

<p>
  If the scope “end_session” is added to the authorization request and the user authorizes the use of this scope an
  “end_session_token” is also returned on the token endpoint.
</p>

<h2 id="implicit-flow">Implicit Flow</h2>
<p>In this flow, the “response_type” must be one of the following:</p>
<ul>
  <li>{{ [openidConnectResponseTypesEnum.ID_TOKEN].join(' ') }}</li>
  <li>{{ [openidConnectResponseTypesEnum.ID_TOKEN, openidConnectResponseTypesEnum.TOKEN].join(' ') }}</li>
</ul>

<p>Since ID does not support plain Oauth2, using just “token” as “response_type” is not supported.</p>

<img src="assets/documentation/openid-connect/flows/id-implicit_flow.png" alt="Implicit Flow, Flow Chart" />

<p>
  In this flow the client directs the user to ID’s authorization endpoint with “response_type=id_token token”, if the
  user authenticates and authorizes, ID redirects the user to the client with an id_token and access_token.
</p>
<p>If “response_type=id_token” then no access_token will be issued.</p>

<app-response-type-token-table [responseTypes]="implicitFlowResponseTypes"></app-response-type-token-table>

<h2 id="hybrid-flow">Hybrid Flow</h2>
<p>In this flow, the “response_type” must be one of the following:</p>
<ul class="list">
  <li>{{ [openidConnectResponseTypesEnum.CODE, openidConnectResponseTypesEnum.ID_TOKEN].join(' ') }}</li>
  <li>{{ [openidConnectResponseTypesEnum.CODE, openidConnectResponseTypesEnum.TOKEN].join(' ') }}</li>
  <li>
    {{
      [
        openidConnectResponseTypesEnum.CODE,
        openidConnectResponseTypesEnum.ID_TOKEN,
        openidConnectResponseTypesEnum.TOKEN
      ].join(' ')
    }}
  </li>
</ul>

<img src="assets/documentation/openid-connect/flows/id-hybrid_flow.png" alt="Hybrid Flow, Flow Chart" />

<p>
  In this flow the client directs the user to ID’s authorization endpoint with “response_type=code id_token token”, if
  the user authenticates and authorizes, ID redirects the user to the client with a code, id_token and access_token.
  Similar to authorization code flow the code can be exchanged with an id_token+access_token by sending a POST request
  to the token endpoint using “authorization_code” as “grant_type”.
</p>

<p>If “response_type=code id_token”, no access_token is issued on authorization.</p>
<p>If “response_type=code token”, no id_token is issued on authorization.</p>

<app-response-type-token-table [responseTypes]="hybridFlowResponseTypes"></app-response-type-token-table>

<p>
  If the scope “offline_access” is added to the authorization request and the user authorizes the use of this scope an
  “refresh_token” is also returned on the token endpoint.
</p>

<p>
  If the scope “end_session” is added to the authorization request and the user authorizes the use of this scope an
  “end_session_token” is also returned on the token endpoint.
</p>

<h2 id="client-credentials-flow">Client Credentials Flow</h2>
<p>
  The client can request an access token using only its client credentials (or other supported means of authentication)
  when the client is requesting access to the protected resources under its control, or those of another resource owner
  that have been previously arranged with the authorization server. The client credentials grant type as defined in
  <a href="https://tools.ietf.org/html/rfc6749#section-4.4" target="_blank" title="RFC 6749 section 4.4"
    >RFC 6749 4.4</a
  >
  must only be used by confidential clients.
</p>

<img
  src="assets/documentation/openid-connect/flows/id-client_credentials_flow.png"
  alt="Client Credentials Flow, Flow Chart"
/>

<p>
  The client authenticates and requests an access token from the token endpoint. Since the client authentication is used
  as the authorization grant, no additional authorization request is needed.<br /><br />
  The request must specify the
  <a routerLink="/documentation/openid-connect/grant-types">Client Credentials grant type</a>. An optional param
  <code class="code">scope</code> can be specified to limit the scope of the returned access token. Specify content type
  <code class="code">application/x-www-form-urlencoded</code> and use UTF-8 character encoding.
</p>

<p>The server issues an access token if client auth is valid.</p>
