<nav>
  @if (heading) {
    <span>{{ heading }}</span>
  }
  @for (menuItem of menuItems; track menuItem) {
    <a
      [routerLink]="menuItem.routerLink"
      fragment="{{ menuItem.fragment }}"
      title="{{ menuItem.title }}"
      >{{ menuItem.text }}</a
      >
    }
  </nav>

  <hr />
