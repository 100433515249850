<h1>Supported grant types</h1>

<h2>Authorization Code (authorization_code)</h2>
<p>
  The Authorization Code grant type is used by confidential and public clients to exchange an authorization code for an
  access token.
</p>
<p>
  After the user returns to the client via the redirect URL, the application will get the authorization code from the
  URL and use it to request an access token.
</p>

<h2>Refresh Token (refresh_token)</h2>
<p>
  The Refresh Token grant type is used by clients to exchange a refresh token for an access token when the access token
  has expired.
</p>
<p>This allows clients to continue to have a valid access token without further interaction with the user.</p>

<h2>Implicit (implicit)</h2>
<p>
  The Implicit Grant has the benefit of requiring only a single call to the IdP; however, it opens up security concerns
  that are not present in the other grants, namely, the user agent can now see the access token. This grant also lacks
  the ability to authenticate the client, which the other grants can do, further introducing attack vectors that the
  authorization grants which require a client secret do not experience.
</p>
<p>Refresh tokens are not supported for this grant type.</p>

<h2>Client Credentials (client_credentials)</h2>
<p>The Client Credentials grant type is used by clients to obtain an access token outside of the context of a user.</p>
<p>This is typically used by clients to access resources about themselves rather than to access a user's resources.</p>
