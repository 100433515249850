import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentationIntroComponent } from '@modules/documentation/pages/documentation-intro/documentation-intro.component';
import { DocumentationComponent } from '@modules/documentation/pages/documentation/documentation.component';
import { OpenidConnectFlowsDocumentationComponent } from '@modules/documentation/pages/openid-connect-flows-documentation/openid-connect-flows-documentation.component';
import { OpenidConnectGrantTypesDocumentationComponent } from '@modules/documentation/pages/openid-connect-grant-types-documentation/openid-connect-grant-types-documentation.component';
import { OpenidConnectTokensDocumentationComponent } from '@modules/documentation/pages/openid-connect-tokens-documentation/openid-connect-tokens-documentation.component';
import { OpenidConnectComponent } from '@modules/documentation/pages/openid-connect/openid-connect.component';
import { SsoDocumentationComponent } from '@modules/documentation/pages/sso-documentation/sso-documentation.component';

const routes: Routes = [
  {
    path: '',
    component: DocumentationComponent,
    title: 'Dokumentasjon',
    children: [
      {
        path: '',
        component: DocumentationIntroComponent,
      },
      {
        path: 'openid-connect',
        component: OpenidConnectComponent,
        title: 'OIDC - Dokumentasjon',
      },
      {
        path: 'openid-connect/tokens',
        component: OpenidConnectTokensDocumentationComponent,
        title: 'Tokens - OIDC - Dokumentasjon',
      },
      {
        path: 'openid-connect/flows',
        component: OpenidConnectFlowsDocumentationComponent,
        title: 'Flows - OIDC - Dokumentasjon',
      },
      {
        path: 'openid-connect/grant-types',
        component: OpenidConnectGrantTypesDocumentationComponent,
        title: 'Grant-types - OIDC - Dokumentasjon',
      },
      {
        path: 'sso',
        component: SsoDocumentationComponent,
        title: 'SSO - Dokumentasjon',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentationRoutingModule {}
