import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentationPageNavigationElementEntity } from '@modules/documentation/components/documentation-page-navigation/documentation-page-navigation-element.entity';
import { ResponseTypeTokenTableRowEntity } from '@modules/documentation/components/response-type-token-table/response-type-token-table-row.entity';
import { OpenidConnectResponseTypeResponseValuesEnum } from '@modules/documentation/enums/openid-connect-response-type-response-values.enum';
import { OpenidConnectResponseTypesEnum } from '@modules/documentation/enums/openid-connect-response-types.enum';

@Component({
  selector: 'app-openid-connect-flows-documentation',
  templateUrl: './openid-connect-flows-documentation.component.html',
  styleUrls: ['./openid-connect-flows-documentation.component.scss'],
})

export class OpenidConnectFlowsDocumentationComponent implements AfterViewInit, OnInit {
  private fragment: string;

  navElementRouterLink: string = '/documentation/openid-connect/flows';
  navElementAuthCodeFlow = new DocumentationPageNavigationElementEntity(
    this.navElementRouterLink,
    'auth-code-flow',
    'Auth Code Flow',
    'Auth Code Flow',
  );
  navElementImplicitFlow = new DocumentationPageNavigationElementEntity(
    this.navElementRouterLink,
    'implicit-flow',
    'Implicit Flow',
    'Implicit Flow',
  );
  navElementHybridFlow = new DocumentationPageNavigationElementEntity(
    this.navElementRouterLink,
    'hybrid-flow',
    'Hybrid Flow',
    'Hybrid Flow',
  );
  navElementClientCredentialsFlow = new DocumentationPageNavigationElementEntity(
    this.navElementRouterLink,
    'client-credentials-flow',
    'Client Credentials Flow',
    'Client Credentials Flow',
  );
  navigationElements: DocumentationPageNavigationElementEntity[] = [
    this.navElementAuthCodeFlow,
    this.navElementImplicitFlow,
    this.navElementHybridFlow,
    this.navElementClientCredentialsFlow,
  ];

  openidConnectResponseTypesEnum = OpenidConnectResponseTypesEnum;

  authCodeFlowResponseTypes: ResponseTypeTokenTableRowEntity[] = [
    new ResponseTypeTokenTableRowEntity(
      [OpenidConnectResponseTypesEnum.CODE],
      [OpenidConnectResponseTypeResponseValuesEnum.CODE],
      [OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN, OpenidConnectResponseTypeResponseValuesEnum.ACCESS_TOKEN],
    ),
  ];

  implicitFlowResponseTypes: ResponseTypeTokenTableRowEntity[] = [
    new ResponseTypeTokenTableRowEntity(
      [OpenidConnectResponseTypesEnum.ID_TOKEN],
      [OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN],
      [],
    ),
    new ResponseTypeTokenTableRowEntity(
      [OpenidConnectResponseTypesEnum.ID_TOKEN, OpenidConnectResponseTypesEnum.TOKEN],
      [OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN, OpenidConnectResponseTypeResponseValuesEnum.ACCESS_TOKEN],
      [],
    ),
  ];

  hybridFlowResponseTypes: ResponseTypeTokenTableRowEntity[] = [
    new ResponseTypeTokenTableRowEntity(
      [OpenidConnectResponseTypesEnum.CODE, OpenidConnectResponseTypesEnum.ID_TOKEN],
      [OpenidConnectResponseTypeResponseValuesEnum.CODE, OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN],
      [OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN, OpenidConnectResponseTypeResponseValuesEnum.ACCESS_TOKEN],
    ),
    new ResponseTypeTokenTableRowEntity(
      [OpenidConnectResponseTypesEnum.CODE, OpenidConnectResponseTypesEnum.TOKEN],
      [OpenidConnectResponseTypeResponseValuesEnum.CODE, OpenidConnectResponseTypeResponseValuesEnum.ACCESS_TOKEN],
      [OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN, OpenidConnectResponseTypeResponseValuesEnum.ACCESS_TOKEN],
    ),
    new ResponseTypeTokenTableRowEntity(
      [
        OpenidConnectResponseTypesEnum.CODE,
        OpenidConnectResponseTypesEnum.ID_TOKEN,
        OpenidConnectResponseTypesEnum.TOKEN,
      ],
      [
        OpenidConnectResponseTypeResponseValuesEnum.CODE,
        OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN,
        OpenidConnectResponseTypeResponseValuesEnum.ACCESS_TOKEN,
      ],
      [OpenidConnectResponseTypeResponseValuesEnum.ID_TOKEN, OpenidConnectResponseTypeResponseValuesEnum.ACCESS_TOKEN],
    ),
  ];

  /**
   * @param route
   */
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {}
  }
}
