import { Component } from '@angular/core';
import { MainMenuDocumentationPageContext } from '@core/main-menu-contexts/documentation-page/main-menu-documentation-page-context.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})

export class DocumentationComponent {
  /**
   * @param mainMenuContext
   */
  constructor(public mainMenuContext: MainMenuDocumentationPageContext) {}
}
