import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-openid-connect-grant-types-documentation',
  templateUrl: './openid-connect-grant-types-documentation.component.html',
  styleUrls: ['./openid-connect-grant-types-documentation.component.scss'],
})

export class OpenidConnectGrantTypesDocumentationComponent implements AfterViewInit, OnInit {
  private fragment: string;

  /**
   * @param route
   */
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {}
  }
}
