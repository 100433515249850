<h1>SSO - Single Sign On</h1>
<p>
  ID benytter seg av JWT som definert i
  <a href="https://tools.ietf.org/html/rfc7519" title="RFC 7519" target="_blank">RFC 7519</a>.
</p>

<app-ff-well
  iconPath="icons/warning_triangle.svg"
  msg='NB! ID aksepterer ikke å legge til nye såkalte "SSO-klienter" med mindre det er tvingende nødvendig.'
></app-ff-well>

<hr />

<div>
  <p>
    SSO-funksjonen fungerer ved å la brukere logge inn i ID, og deretter videresende dem til den eksterne tjenesten de
    forsøkte å logge inn på. Når en bruker videresendes inneholder URL-en en parameter, "jwt". Denne parameterens verdi
    er JWT som inneholder all nødvendig informasjon for at det eksterne systemet skal kunne logge inn brukeren.
  </p>
  <p>
    For å kunne integrere med løsningen vår må du først opprette en klient. Når denne er opprettet lagrer du app-id og
    hemmelig nøkkel på en sikker måte. Det er kombinasjonen av disse to tilfeldig genererte tekststrengene som brukes
    for å verifisere signaturdelen av JWT som blir sent i URL etter vellykket innlogging.
  </p>
  <p>Når en bruker ønsker å logge inn i klienten din vil de bli sendt igjennom følgende steg:</p>

  <ol>
    <li>Bruker navigerer til en side i din klient (f.eks. https://yourclient.com/login)</li>
    <li>
      For å logge inn bruker, videresender du brukeren til
      https://id.fagforbundet.no/sso?return_to=https://yourclient.com/login&amp;app_id=idOfYourClient
    </li>
    <li>Bruker logger inn i ID</li>
    <li>
      Etter vellykket innlogging blir brukeren sendt til URL-en som kom i return_to-parameteren (f.eks.
      https://yourclient.com/login?jwt=theJWTgoeshere)
    </li>
    <li>Din klient dekoder JWT, og validerer signaturen</li>
    <li>Hvis signaturen er gyldig må klienten din sjekke om brukeren allerede eksisterer</li>
    <li>Hvis brukeren eksisterer bør klienten din oppdatere brukerens informasjon med det som ble sendt i JWT</li>
    <li>Hvis brukeren ikke eksisterer bør klienten din opprette en ny bruker med informasjonen som ble sendt i JWT</li>
  </ol>

  <p>
    NB! Det er viktig at klienten din faktisk validerer signaturen i JWT ved å bruke tidligere nevnte app-id og hemmelig
    nøkkel. Dette er for å forsikre deg om at JWT faktisk ble sendt fra ID, og det er den eneste måten du kan være
    sikker på at informasjonen i JWT er korrekt. Det finnes mange biblioteker du kan bruke for å dekode og validere JWT.
    Sjekk ut denne adressen for å se noen av dem:
    <a href="https://jwt.io" title="https://jwt.io" target="_blank">https://jwt.io</a>
  </p>

  <p>Dette er et eksempel på en JWT:</p>

  <code class="jwt"
    >eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJlNjc2NDZmMmE1YjJmM2FjMjdkNmY5NzcyNzdkNjE3ZCIsImlhdCI6MTUwOTEwNTcyOCwibWZhIjp0cnVlLCJzdWIiOjEyMzQsIm5hbWUiOiJUZXN0IFVzZXIiLCJmaXJzdE5hbWUiOiJUZXN0IiwibGFzdE5hbWUiOiJVc2VyIiwiZW1haWwiOiJ0ZXN0LnVzZXJAdGVzdC50ZXN0IiwicGhvbmUiOiIxMjM0NTY3OCIsIm1lbWJlcklkIjpudWxsLCJlbXBsb3llZU51bWJlciI6bnVsbH0.cBSBLdRlnStBbGfim4zt4FAiq--nAcrzwXvKyuWbRUA</code
  >

  <p>
    Forsøk å lime den inn i tekstfeltet på
    <a href="https://jwt.io" title="https://jwt.io" target="_blank">https://jwt.io</a>
  </p>
</div>
