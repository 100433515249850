<h1>Tokens</h1>
<p>
  All tokens issued by ID, are JSON Web Token (JWT) as defined in
  <a href="https://tools.ietf.org/html/rfc7519" target="_blank" title="RFC 7519">RFC 7519</a>.
</p>
<p>
  The tokens are signed with ID’s public key and MUST be validated against this key using the method described in
  section 7.2 of RFC 7519.
</p>

<h2>Access Token (access_token)</h2>
<p>
  This token is used as a bearer token against different services to gain access to resources on behalf of the user or
  client this token is issued to.
</p>

<h2>Refresh Token (refresh_token)</h2>
<p>
  This token is used against the token endpoint with the “refresh_token” grant to get a new access_token and id_token.
</p>
<p>A refresh token is only issued if the “offline_access” scope is authorized.</p>

<h2>ID Token (id_token)</h2>
<p>This token contains claims about the user</p>

<h2>End Session Token (end_session_token)</h2>
<p>This token is used against the revoke endpoint to end a user's session.</p>
