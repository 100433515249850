import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MainMenuItem } from '@core/entities/site/main-menu.entity';
import { MainMenuCommonContext } from '@core/main-menu-contexts/common/main-menu-common-context';
import { MainMenuContextInterface } from '@shared/components/main-menu/main-menu-context-interface';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MainMenuDocumentationPageContext extends MainMenuCommonContext implements MainMenuContextInterface {
  private _mainMenuItems$: ReplaySubject<MainMenuItem[]> = new ReplaySubject<MainMenuItem[]>(1);

  constructor(private _router: Router) {
    super();
    this._init();
  }

  getMainMenuItems(): Observable<MainMenuItem[]> {
    return this._mainMenuItems$;
  }

  /**
   * @private
   */
  private _init(): void {
    this._buildMenu();
    this.setActive(this._router.url);

    // Set active item on navigation
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => event.url !== event.urlAfterRedirects),
        tap((n: NavigationEnd) => {
          this.setActive(n.url);
        }),
      )
      .subscribe();
  }

  /**
   * @private
   */
  private _buildMenu() {
    this.mainMenuItems = [];

    const documentationMenuItem = new MainMenuItem(
      'Dokumentasjon',
      'intro',
      'icons/document_in_box.svg',
      '/documentation',
    );

    this.mainMenuItems.push(documentationMenuItem);

    const openIdConnectMenuItem = new MainMenuItem(
      'OIDC - OpenID Connect',
      'openid-connect',
      'icons/document_in_box.svg',
      '/documentation/openid-connect',
    );

    openIdConnectMenuItem.addChild(
      new MainMenuItem('Tokens', 'tokens', undefined, '/documentation/openid-connect/tokens'),
    );

    openIdConnectMenuItem.addChild(
      new MainMenuItem('Flows', 'flows', undefined, '/documentation/openid-connect/flows'),
    );

    openIdConnectMenuItem.addChild(
      new MainMenuItem('Grant Types', 'grant-types', undefined, '/documentation/openid-connect/grant-types'),
    );

    this.mainMenuItems.push(openIdConnectMenuItem);

    const ssoMenuItem = new MainMenuItem(
      'SSO - Single Sign On',
      'sso',
      'icons/document_in_box.svg',
      '/documentation/sso',
    );

    this.mainMenuItems.push(ssoMenuItem);

    this._mainMenuItems$.next(this.mainMenuItems);
  }
}
