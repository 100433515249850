import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-service-documentation',
  templateUrl: './sso-documentation.component.html',
  styleUrls: ['./sso-documentation.component.scss'],
})
export class SsoDocumentationComponent implements AfterViewInit, OnInit {
  private _fragment: string;

  /**
   * @param route
   */
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this._fragment = fragment;
    });
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this._fragment).scrollIntoView();
    } catch (e) {}
  }
}
