import { OpenidConnectResponseTypeResponseValuesEnum } from '@modules/documentation/enums/openid-connect-response-type-response-values.enum';
import { OpenidConnectResponseTypesEnum } from '@modules/documentation/enums/openid-connect-response-types.enum';

export class ResponseTypeTokenTableRowEntity {
  responseType: OpenidConnectResponseTypesEnum[];
  authorizationEndpoint: OpenidConnectResponseTypeResponseValuesEnum[];
  tokenEndpoint: OpenidConnectResponseTypeResponseValuesEnum[];

  /**
   *
   * @param responseType
   * @param authorizationEndpoint
   * @param tokenEndpoint
   */
  constructor(
    responseType: OpenidConnectResponseTypesEnum[],
    authorizationEndpoint: OpenidConnectResponseTypeResponseValuesEnum[],
    tokenEndpoint: OpenidConnectResponseTypeResponseValuesEnum[],
  ) {
    this.responseType = responseType;
    this.authorizationEndpoint = authorizationEndpoint;
    this.tokenEndpoint = tokenEndpoint;
  }
}
