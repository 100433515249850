<table>
  <tr>
    <th>Response Type</th>
    <th>Authorization Endpoint</th>
    <th>Token Endpoint</th>
  </tr>
  @for (responseType of responseTypes; track responseType) {
    <tr>
      <td>{{ responseType.responseType.join(' ') }}</td>
      <td>
        {{ responseType.authorizationEndpoint.length === 0 ? '-' : responseType.authorizationEndpoint.join(' + ') }}
      </td>
      <td>{{ responseType.tokenEndpoint.length === 0 ? '-' : responseType.tokenEndpoint.join(' + ') }}</td>
    </tr>
  }
</table>
