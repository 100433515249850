/**
 *
 */
export class DocumentationPageNavigationElementEntity {
  routerLink: string;
  fragment: string;
  title: string;
  text: string;

  /**
   *
   * @param routerLink
   * @param fragment
   * @param title
   * @param text
   */
  constructor(routerLink: string, fragment: string, title: string, text: string) {
    this.routerLink = routerLink;
    this.fragment = fragment;
    this.title = title;
    this.text = text;
  }
}
