import { Component, Input } from '@angular/core';
import { DocumentationPageNavigationElementEntity } from '@modules/documentation/components/documentation-page-navigation/documentation-page-navigation-element.entity';

@Component({
  selector: 'app-documentation-page-navigation',
  templateUrl: './documentation-page-navigation.component.html',
  styleUrls: ['./documentation-page-navigation.component.scss'],
})
export class DocumentationPageNavigationComponent {
  @Input() heading: string = null;
  @Input() menuItems: DocumentationPageNavigationElementEntity[] = [];
}
