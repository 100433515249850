import { Component, Input } from '@angular/core';
import { ResponseTypeTokenTableRowEntity } from '@modules/documentation/components/response-type-token-table/response-type-token-table-row.entity';

@Component({
  selector: 'app-response-type-token-table',
  templateUrl: './response-type-token-table.component.html',
  styleUrls: ['./response-type-token-table.component.scss'],
})
export class ResponseTypeTokenTableComponent {
  @Input() responseTypes: ResponseTypeTokenTableRowEntity[];
}
